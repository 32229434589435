import * as React from "react";
import { useRecoilState } from "recoil";
import {
  Drawer as FluentDrawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
} from "@fluentui/react-components/unstable";
import { Button } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
  drawerMainCategoryAtom,
  isDrawerOpenedAtom,
} from "../../../../stores/layout";
import SubMenuPost from "./SubMenuPost";

interface DrawerProps {}
const Drawer: React.FC<DrawerProps> = () => {
  const [isOpened, setIsOpened] = useRecoilState(isDrawerOpenedAtom);
  const [drawerMainCategory, setDrawerMainCategory] = useRecoilState(
    drawerMainCategoryAtom,
  );

  const handleClose = () => {
    setIsOpened(false);
    setDrawerMainCategory(undefined);
  };

  return (
    <FluentDrawer
      separator
      open={isOpened}
      type="inline"
      onOpenChange={(_, { open }) => setIsOpened(open)}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => handleClose()}
            />
          }
        >
          {drawerMainCategory?.title}
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        {drawerMainCategory?.description}
        <SubMenuPost />
        {/* {drawerMainCategory?.layout === drawerLayoutEnum.default */}
        {/*  && <SubMenuPost />} */}
      </DrawerBody>
    </FluentDrawer>
  );
};

export default Drawer;
