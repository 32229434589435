import * as React from "react";
import styled from "styled-components";
import { LeafThree16Filled } from "@fluentui/react-icons";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Button, Tooltip } from "@fluentui/react-components";
import {
  drawerMainCategoryAtom,
  isDrawerOpenedAtom,
} from "../../../stores/layout";
import DynamicIcon from "../DynamicIcon";
import { useFindMainCategory } from "../../../query/mainCategory";

const SidebarWrap = styled.div`
  height: 100vh;
  flex: 0 0 50px;
  background-color: var(--colors-brand-background);
  flex-direction: column;
`;
const IconMenu = styled.div`
  height: 50px;
  width: 50px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  .logo {
    width: 30px;
    height: 30px;
  }
  .icon-menu-button {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    min-width: 50px;
    height: 50px;
  }
`;

interface SidebarProps {}
const Sidebar: React.FC<SidebarProps> = () => {
  const setIsDrawerOpened = useSetRecoilState(isDrawerOpenedAtom);
  const [drawerMainCategory, setDrawerMainCategory] = useRecoilState(
    drawerMainCategoryAtom,
  );

  const data = useFindMainCategory();

  const handleMenuClick = (category: Queries.SanityMainCategory) => {
    const isCategoryOpened = category?.id === drawerMainCategory?.id;
    setIsDrawerOpened(!isCategoryOpened);
    setDrawerMainCategory(isCategoryOpened ? undefined : { ...category });
  };

  return (
    <SidebarWrap>
      {/* 로고 */}
      <IconMenu>
        <LeafThree16Filled className="logo" />
      </IconMenu>
      {/* 메인 카테고리 */}
      {data?.allSanityMainCategory?.nodes.map((category) => (
        <IconMenu
          onClick={() => {
            handleMenuClick(category);
          }}
          key={category.id}
        >
          <Tooltip
            content={String(category.title)}
            positioning="after"
            relationship="label"
          >
            <Button
              icon={<DynamicIcon iconName={category.iconName} />}
              size="large"
              className="icon-menu-button"
            />
          </Tooltip>
        </IconMenu>
      ))}
    </SidebarWrap>
  );
};

export default Sidebar;
