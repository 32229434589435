import * as React from "react";
import loadable from "@loadable/component";

interface DynamicIconProps {
    iconName: string | null | undefined;
}

const DEFAULT_FALLBACK_ICON_KEY = "BookQuestionMark20Regular";
const DynamicIcon = ({ ...props }: DynamicIconProps) => {
  const ImportedIcon = loadable(() => import("@fluentui/react-icons"), {
    resolveComponent: (el:any) => el[props.iconName || DEFAULT_FALLBACK_ICON_KEY],
  });

  return <ImportedIcon />;
};

export default DynamicIcon;
