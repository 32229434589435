import { graphql, useStaticQuery } from "gatsby";

// Queries
export const postQuery = graphql`
  query posts {
    allSanityPost(sort: { fields: _updatedAt, order: DESC }) {
      nodes {
        title
        id
        _updatedAt
        slug {
          current
        }
        subCategory {
          title
          id
        }
      }
    }
  }
`;

// Hooks
export const useFindPost = () => useStaticQuery<Queries.Query>(postQuery);
export const useFindPostBySubCategory = (id: string | undefined) => {
  const data = useFindPost();
  return data?.allSanityPost?.nodes?.filter(
    (post) => post.subCategory?.some((subCategory) => subCategory?.id === id),
  );
};
