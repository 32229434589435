import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@fluentui/react-components";
import { MoreHorizontal16Regular } from "@fluentui/react-icons";
import { useFindPostBySubCategory } from "../../../query/post";
import { createElapsedTime } from "../../../lib/dateTimeUtil";

const CellContent = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
`;

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

const UpdatedAt = styled.div`
  width: 100px;
  text-align: right;
  font-size: 9px;
  margin-left: auto;
  opacity: 0.6;
`;

const ExpandButton = styled(Button)`
  width: 100% !important;
  max-width: 100%;
  border: none;
`;

interface PostListProps {
  subCategoryId?: string;
  defaultCount?: number;
  addCount?: number;
}

const PostList: React.FC<PostListProps> = ({
  subCategoryId,
  defaultCount = 1,
  addCount = 10,
}) => {
  const posts = useFindPostBySubCategory(subCategoryId);
  const [visibleCount, setVisibleCount] = useState(defaultCount);

  const expand = () => {
    setVisibleCount(visibleCount + addCount);
  };

  return (
    <>
      <Table size="extra-small">
        <TableBody>
          {posts?.slice(0, visibleCount)?.map((post) => (
            <TableRow key={post.id}>
              <TableCell>
                <Link to={`/post/${post.slug?.current}`}>
                  <CellContent>
                    <Title>{post.title}</Title>
                    <UpdatedAt>{createElapsedTime(post._updatedAt)}</UpdatedAt>
                  </CellContent>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        {posts.length > visibleCount && (
          <ExpandButton
            size="small"
            icon={<MoreHorizontal16Regular />}
            onClick={expand}
          />
        )}
      </div>
    </>
  );
};

export default PostList;
