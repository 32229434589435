import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import React from "react";
import { RecoilRoot } from "recoil";
import Layout from "./components/common/layout/Layout";

const RootElement: React.FC = ({ element }: any) => (
  <FluentProvider theme={teamsLightTheme}>
    <RecoilRoot>
      <Layout>{element}</Layout>
    </RecoilRoot>
  </FluentProvider>
);

export default RootElement;
