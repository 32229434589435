import { graphql, useStaticQuery } from "gatsby";

export const subCategoryQuery = graphql`
  query SubCategories {
    allSanitySubCategory {
      nodes {
        title
        id
        mainCategory {
          title
          id
        }
      }
    }
  }
`;

export const useFindSubCategory = () =>
  useStaticQuery<Queries.Query>(subCategoryQuery);
export const useFindSubCategoryByMainCategory = (id?: string) => {
  const data = useFindSubCategory();
  return data?.allSanitySubCategory?.nodes?.filter(
    (subCategory) => subCategory.mainCategory?.[0]?.id === id,
  );
};
