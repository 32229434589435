import * as React from "react";
import { useRecoilValue } from "recoil";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";
import { drawerMainCategoryAtom } from "../../../../stores/layout";
import { useFindSubCategoryByMainCategory } from "../../../../query/subCategory";
import PostList from "../../Post/PostList";

interface SubMenuPostProps {}
const SubMenuPost: React.FC<SubMenuPostProps> = () => {
  const drawerMainCategory = useRecoilValue(drawerMainCategoryAtom);
  const subCategories = useFindSubCategoryByMainCategory(
    drawerMainCategory?.id,
  );

  return (
    <Accordion collapsible>
      {subCategories?.map((category) => (
        <AccordionItem value={category.id} key={category.id}>
          <AccordionHeader>{category.title}</AccordionHeader>
          <AccordionPanel>
            <PostList subCategoryId={category?.id} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default SubMenuPost;
