import dayjs from "dayjs";

export const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const createElapsedTime = (date: string | null) => {
  const start = dayjs(date);
  const end = dayjs();
  const diffSecond = end.diff(start) / 1000;

  const units = [
    { name: "년", second: 60 * 60 * 24 * 365 },
    { name: "개월", second: 60 * 60 * 24 * 30 },
    { name: "일", second: 60 * 60 * 24 },
    { name: "시간", second: 60 * 60 },
    { name: "분", second: 60 },
  ];

  const matchedUnit = units.find(
    (unit) => Math.floor(diffSecond / unit.second) > 0,
  );

  if (!matchedUnit) return "방금 전";
  return `${Math.floor(diffSecond / matchedUnit.second)}
  ${matchedUnit.name} 전`;
};

export const formatDateTime = (date?: string | null) =>
  date ? dayjs(date).format("YYYY-MM-DD HH:mm") : "Invalid Date";
