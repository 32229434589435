import * as React from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Drawer from "./Drawer/Drawer";

const LayoutWrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;

const ContentWrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;
const ChildrenWrap = styled.div`
  flex: 1;
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  grid-auto-rows: max-content;
`;

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <LayoutWrap>
    <Sidebar />
    <ContentWrap>
      <Drawer />
      <ChildrenWrap>{children}</ChildrenWrap>
    </ContentWrap>
  </LayoutWrap>
);

export default Layout;
