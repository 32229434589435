import { atom } from "recoil";

export const isDrawerOpenedAtom = atom({
  key: "isDrawerOpened",
  default: false,
});
export const drawerMainCategoryAtom = atom<
  Queries.SanityMainCategory | undefined
>({
  key: "drawerMainCategory",
  default: undefined,
});
