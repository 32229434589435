import { graphql, useStaticQuery } from "gatsby";

// Queries
export const mainCategoryQuery = graphql`
  query MainCategories {
    allSanityMainCategory {
      nodes {
        layout
        title
        id
        iconName
        description
      }
    }
  }
`;

// Hooks
export const useFindMainCategory = () =>
  useStaticQuery<Queries.Query>(mainCategoryQuery);
